import React from 'react'
import styles from 'styles/components/PricingHero.module.sass'
import styles2 from 'styles/layout/PricingSection.module.sass'

/**
 * PricingHero component displays a hero section for the pricing page, introducing
 * the pricing plans with a title, main focus text, and a discount message.
 *
 * @component
 *
 * @example
 * // Renders the PricingHero component
 * <PricingHero />
 */
const PricingHero:React.FC = () => {
    return (
        <div className={styles['pricing-hero-container']}>
            <p className={styles['short-intro']}>ZERONE PRICING</p>
            <h2 className={styles['focus']}>Get the most out of data<span className={styles['line-break']} /> with our plans</h2>
            <p className={styles2['note']}>
                    All plans are <span className={styles2['highlight']}>50% off now!</span> Workspace is coming soon - grab the discount before it launches at full price!
                </p>
        </div>
    )
}

export default PricingHero;