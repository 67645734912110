import React from 'react'
import styles from 'styles/layout/ProfileHeaderSection.module.sass'
import HeaderNavigation from 'components/layout/header/HeaderNavigation'
import BasicProfile from 'interface/BasicProfile';
import ProfileHeaderSummary from 'components/layout/profile/ProfileHeaderSummary';

interface ProfileHeaderSectionProps {
    profile: BasicProfile | null;
    tab: 'basic' | 'reports' | 'account';
    setTab: (tab: 'basic' | 'reports' | 'account') => void;
}

/**
 * ProfileHeaderSection component - Displays the header section for the profile page.
 *
 * This component includes a navigation header (`HeaderNavigation`) and a summary
 * of the profile (`BasicProfile`). The header is styled for a dark theme, and
 * the profile summary is rendered only if a valid profile is passed as a prop.
 *
 * @component
 * @param {ProfileHeaderSectionProps} props - The properties for the ProfileHeaderSection component.
 * @param {BasicProfile | null} props.profile - The profile data to be displayed in the header section.
 *
 * @example
 * // Example usage:
 * <ProfileHeaderSection profile={profileData} />
 *
 * @returns {JSX.Element} The rendered ProfileHeaderSection component.
 */
const ProfileHeaderSection: React.FC<ProfileHeaderSectionProps> = ({ profile, tab, setTab }) => {
    return (
        <section className={styles['profile-header-section']}>
            <HeaderNavigation theme='dark' />
            {profile && (
                <ProfileHeaderSummary profile={profile} tab={tab} setTab={setTab} />
            )}
        </section>
    )
}

export default ProfileHeaderSection;