import endpoints from "constants/api/Endpoints";
import { logError } from "services/logService/errorLogger";
import Account from "interface/Account";
import BasicProfile from "interface/BasicProfile";

const fileName = 'AccountApiService'

/**
 * Fetches account profile data at the specified level.
 * 
 * @param {'summary' | 'profile'} level - The level of detail to retrieve, either 'summary' or 'profile'.
 * @returns {Promise<Account | null>} A promise resolving to an Account object if successful, or null if an error occurs or data is unavailable.
 * 
 * @throws Will log an error if an exception occurs or if the `level` parameter is missing.
 * 
 * @example
 * // Fetch profile data at the 'summary' level
 * const profile = await fetchProfile('summary');
 * if (profile) {
 *   console.log('Profile:', profile);
 * } else {
 *   console.error('Failed to fetch profile.');
 * }
 */
export const fetchProfile = async (level: 'summary' | 'profile'): Promise<Account | BasicProfile | null> => {
    try {
        if (!level) {
            logError('Missing account retrieval level.', level, fileName)
            return null
        }

        const result = await endpoints.fetchAccountData({ tag: fileName, level })
        if (result.success && result.data) {
            return result.data
        }
        return null
    } catch (error) {
        logError(
            'Error retrieving account data',
            { error, level },
            fileName
        )
        return null
    }
}