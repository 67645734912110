/**
 * Stores the JWT token either in HttpOnly cookies or sessionStorage based on the environment.
 *
 * @param {string} token - The JWT token to be stored.
 * @param {boolean} [isSecure=true] - Flag indicating whether to store the token in HttpOnly cookies (true) or sessionStorage (false).
 * 
 * @remarks
 * - When `isSecure` is true, the token is stored in HttpOnly cookies for production use cases.
 * - When `isSecure` is false, the token is stored in sessionStorage for development or testing purposes.
 * 
 * @fires window#authChange - Dispatches an 'authChange' event on the window object after storing the token.
 */
const storeJwtToken = (token: string, isSecure: boolean = true): void => {
    if (isSecure) {
        // Store token in HttpOnly cookies (production use case)
        document.cookie = `auth_token=${token}; path=/; Secure; SameSite=Strict; HttpOnly`;
    } else {
        // For development environments or testing purposes
        sessionStorage.setItem('auth_token', token);
    }
    window.dispatchEvent(new Event('authChange'))
}

export default storeJwtToken