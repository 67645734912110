import React from 'react';
import styles from 'styles/layout/ContactUs.module.sass';
import callUsIcon from 'assets/images/svg/call-us-3-d.svg';
import emailUsIcon from 'assets/images/svg/email-us.svg';
import whatsappIcon from 'assets/images/svg/whatsappicon.svg';

/**
 * Interface representing a contact method.
 * 
 * @property {string} method - The name of the contact method (e.g., "Call us").
 * @property {string} details - The contact details (e.g., phone number or email address).
 * @property {string} href - The link to initiate the contact (e.g., `tel:` or `mailto:` link).
 * @property {string} icon - The path to the icon representing the contact method.
 */
interface ContactMethod {
    method: string;
    details: string;
    href: string;
    icon: string;
}

/**
 * A list of supported contact methods, each containing method, details, href, and icon.
 */
const supportedContactMethods: ContactMethod[] = [
    {
        method: "Call us",
        details: "+1 236 889 5299",
        href: "tel:+1 236 889 5299",
        icon: callUsIcon
    },
    {
        method: "Shoot us a mail",
        details: "info@zeroneanalytiqs.com",
        href: "mailto:info@zeroneanalytiqs.com",
        icon: emailUsIcon
    },
    {
        method: "Whatsapp",
        details: "+233 53 885 5417",
        href: "https://api.whatsapp.com/send/?phone=233538855417&text=Hello+I+would+like+to+inquire+about+your+services&type=phone_number&app_absent=0 ",
        icon: whatsappIcon
    }
];

/**
 * ContactUs component renders a contact section displaying available contact methods.
 * 
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import ContactUs from 'components/layout/footer/ContactUs';
 * 
 * const App: React.FC = () => (
 *   <div>
 *     <ContactUs />
 *   </div>
 * );
 *
 * export default App;
 * ```
 * 
 * @returns {JSX.Element} The JSX element displaying the contact section.
 */
const ContactUs: React.FC = () => {
    return (
        <section className={styles['contact-us-section']}>
            <div className={styles['heading-container']}>
                <h2 className={styles['title']}>
                    GET IN TOUCH WITH US
                </h2>
                <h2 className={styles['subtitle']}>
                    Contact Us Today!
                </h2>
            </div>
            <div className={styles['contact-container']}>
                {supportedContactMethods.map((contact) => (
                    <div
                        key={contact.method}
                        className={styles.item}
                        aria-label={`Contact via: ${contact.method}`}>
                            <img src={contact.icon} alt={`${contact.method} icon`} className={styles.icon} />
                            <p className={styles['method']}>{contact.method}</p>
                            <a href={contact.href} className={styles['details']}>{contact.details}</a>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ContactUs;