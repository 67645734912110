import { v4 as uuidv4 } from 'uuid'

/**
 * infoLogger function logs an info message with a timestamp, unique log ID, and additional metadata to the console.
 * 
 * @function
 * 
 * @param {string} message - The info message to be logged.
 * @param {Record<string, any>} meta - Additional metadata related to the info message.
 * @param {string} tag - The tag associated with the log message.
 * 
 * @example
 * infoLogger("User logged in", { user: "JohnDoe" }, "authService.ts");
 * // Logs the info message with timestamp, log ID, tag, message, and metadata.
 * 
 * @see uuidv4 - Generates a unique identifier for each log instance.
 */
const infoLogger = (message: string, meta: Record<string, any>, tag: string): void => {
    const timestamp = new Date().toISOString()
    const logId = uuidv4()

    const tagEntry = tag ? `[Tag: ${tag}]` : ''

    const logEntry = `[${timestamp}] [Log ID: ${logId}]`
    
    console.info(`${logEntry} ${tagEntry} [Level: INFO] ${message}`, meta)
}


/**
 * Logs an info message with metadata and a tag.
 * 
 * @param {string} message - The info message to be logged.
 * @param {Record<string, any>} meta - Additional metadata related to the info message.
 * @param {string} tag - The tag associated with the log message.
 * 
 * @example
 * logInfo("User logged in", { user: "JohnDoe" }, "authService.ts");
 * // Logs the info message with timestamp, log ID, tag, message, and metadata.
 * 
 * @see infoLogger
 */
const logInfo = (message: string, meta: Record<string, any>, tag: string): void => {
    infoLogger(message, meta, tag)
}

export default logInfo;