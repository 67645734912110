import { PriceCardProps } from "interface/PriceCardProps";

/**
 * platinumPricePlan object defines the properties and features of the platinum subscription plan.
 * It includes pricing information, extensive features, and a click handler for the subscription button.
 * 
 * @constant
 * 
 * @type {PriceCardProps}
 * 
 * @example
 * console.log(platinumPricePlan.features);
 * // Output: ["Access AI-Powered Workspace", "Unlimited workspace projects per user per month", "Unlimited access to all reports", "On-demand training"]
 * 
 * @see PriceCardProps - Interface representing the structure of a price plan.
 */
export const platinumPricePlan : PriceCardProps = {
    subscriptionType: 'platinum',
    moreInfo: '50 User Seats',
    price: '2500',
    nonAfricanPrice: '5000',
    subscriptionTerm: 'per month',
    features: ['Access to AI-Powered Workspace', 'Unlimited workspace projects per user per month', 'Unlimited access to all reports', 'On-demand training'],
    onButtonClick() {
        console.log('Gold plan clicked')
    },
}