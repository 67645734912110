/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { fetchApiData } from "constants/api/ApiHelper";
import { post } from "constants/api/ApiClient";
import EndpointProps from "interface/EndpointProps";
import SignUpRequest from "constants/api/models/SignUpRequest";
import { SignUpResponse } from "constants/api/models/SignUpResponse";

/**
 * Simplified API handler for user signup.
 * 
 * This function performs the API call to submit the signup details
 * and processes the response minimally.
 * 
 * @async
 * @function signUpUser
 * @param {EndpointProps & { data: SignUpRequest }} params - The request parameters containing a unique tag and signup payload.
 * @returns {Promise<{ data: SignUpResponse | null; error: string | null }} - A promise that resolves to an object containing the signup response data or an error message.
 */
export async function signUpUser({
    tag,
    data,
}: EndpointProps & { data: SignUpRequest }): Promise<{ data: SignUpResponse | null; error: string | null }> {
    if (!data) {
        return {
            data: null,
            error: "Invalid signup request: Missing data payload.",
        };
    }

    const result = await fetchApiData<SignUpResponse>(
        tag,
        () =>
            post<SignUpResponse>({
                tag,
                url: "/auth/signup",
                body: data,
            }),
        (responseData) => responseData
    );

    if (result.response.status === "error") {
        return {
            data: null,
            error: result.response.message || "An error occurred during signup.",
        };
    }

    return {
        ...result.response.data || null,
        error: null,
    };
}

/**
 * Interface representing the response from a login API endpoint.
 */
export interface LoginResponse {
    /**
     * The status of the login attempt.
     */
    status: string;

    /**
     * A message providing additional information about the login attempt.
     */
    message: string;

    /**
     * The timestamp when the response was generated.
     */
    timestamp: string;
}

/**
 * Logs in a user with the provided email and password.
 *
 * @param {EndpointProps & { data: { email: string, password: string }}} params - The parameters for the login request.
 * @param {string} params.tag - The tag for the endpoint.
 * @param {Object} params.data - The data payload for the login request.
 * @param {string} params.data.email - The email of the user.
 * @param {string} params.data.password - The password of the user.
 * @returns {Promise<{ success: boolean, error: string | null }>} A promise that resolves to an object indicating the success or failure of the login attempt.
 * @throws Will throw an error if the fetchApiData or post function fails.
 */
export async function logInUser({ tag, data }: EndpointProps & { data: { email: string, password: string }}): Promise<{ success: boolean, error: string | null }> {
    if (!data) {
        return {
            success: false,
            error: "Invalid login request: Missing data payload.",
        };
    }

    const result = await fetchApiData<LoginResponse>(
        tag,
        () => 
            post<LoginResponse>({
                tag,
                url: '/auth/login',
                body: data
            }),
        (responseData) => responseData
    )

    if (result.response.status === 'error') {
        return {
            success: false,
            error: result.response.message || 'An error occrred during login.'
        }
    }

    return {
        success: true,
        error: null
    }
}

/* eslint-enable @typescript-eslint/no-redundant-type-constituents */
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-argument */
