import ReusableIcon from 'components/common/ReusableIcon';
import React from 'react'
import styles from 'styles/components/DownloadReportCard.module.sass'
import { Link } from 'react-router-dom';
import zeroneLogoDark from 'assets/images/svg/logo-dark.svg'
import PrimaryButton from 'components/common/PrimaryButton';

/**
 * Props for the DownloadReportCard component.
 * 
 * @interface DownloadReportCardProps
 * 
 * @property {string} title - The title of the report card.
 * @property {string} url - The URL to download the report.
 * @property {() => void} onClose - Callback function to handle the close action.
 */
interface DownloadReportCardProps {
    title: string;
    url: string;
    onClose: () => void;
}

const navigateToUrl = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
}

/**
 * DownloadReportCard component renders a card that provides a download link for a report.
 * 
 * @component
 * @param {DownloadReportCardProps} props - The properties for the DownloadReportCard component.
 * @param {string} props.title - The title of the report.
 * @param {string} props.url - The URL to download the report.
 * @param {function} props.onClose - The function to call when the close button is clicked.
 * 
 * @returns {JSX.Element} The rendered DownloadReportCard component.
 */
const DownloadReportCard: React.FC<DownloadReportCardProps> = ({ title, url, onClose }) => {
    return (
        <div className={styles['download-report-card']}>
            <div className={styles['header']}>
                <button className={styles['close-button']} onClick={onClose}>
                    <ReusableIcon 
                        icon={"qlementine-icons:close-16"} 
                        className={styles['close-icon'] || 'close-icon'}
                        color={"#707070"}
                    />
                </button>
            </div>
            <div className={styles['main-content']}>
                <div className={styles['title-wrapper']}>  
                    <div className={styles['logo-container']}>
                        <Link to="/">
                            <img src={zeroneLogoDark} className={styles['zerone-logo']} alt="Zerone AnalytiQs Logo" />
                        </Link>
                    </div>
                    <h2 className={styles['title']}>{title}</h2>
                </div>
                <div className={styles['message-wrapper']}>
                    <p className={styles['message']}>Your report is now available for download. Please click the button below to proceed with the download.</p>
                    <p className={styles['note']}>Please note that this link will expire in 24 hours. You can always access your report from your account.</p>
                </div>
                <PrimaryButton
                    text={'Download'}
                    primaryColor='#FF5522'
                    textColor='#FFFFFF'
                    hoverColor='#FFFFFF'
                    hoverTextColor='#FF5522'
                    icon={''}
                    doSomething={() => navigateToUrl(url)} 
                    width={'auto'}
                />                   
            </div>
            <div className={styles['footer']}>
                <p>This report is powered by Zerone AnalytiQs © {new Date().getFullYear()}.</p>
            </div>
        </div>
    )
}

export default DownloadReportCard;

