import React from 'react'
import styles from 'styles/layout/ProfileBodySection.module.sass'
import BasicProfile from 'interface/BasicProfile';
import BasicInfo from 'components/layout/profile/BasicInfo';
import MyReports from 'components/layout/profile/MyReports';
import AccountDetails from 'components/layout/profile/AccountDetails';

interface ProfileBodySectionProps {
    profile: BasicProfile | null;
    tab: 'basic' | 'reports' | 'account';
}

/**
 * ProfileBodySection component - Displays the body section for the profile page.
 *
 * This component includes a summary of the profile (`BasicProfile`). The profile
 * summary is rendered only if a valid profile is passed as a prop.
 *
 * @component
 * @param {ProfileBodySectionProps} props - The properties for the ProfileBodySection component.
 * @param {'basi' | 'reports' | 'account'} props.tab - The tab to be displayed in the body section.
 * @param {BasicProfile | null} props.profile - The profile data to be displayed in the body section.
 *
 * @example
 * // Example usage:
 * <ProfileBodySection profile={profileData} />
 *
 * @returns {JSX.Element} The rendered ProfileBodySection component.
 */
const ProfileBodySection: React.FC<ProfileBodySectionProps> = ({ profile, tab }) => {
    return (
        <section className={styles['profile-body-section']}>
            {profile && (
                tab === 'basic' ? (
                    <BasicInfo profile={profile} />
                ) :
                tab === 'reports' ? (
                    <MyReports />
                ) :
                tab === 'account' ? (
                    <AccountDetails />
                ) : null
            )}
        </section>
    )
}

export default ProfileBodySection;