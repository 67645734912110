//import SecondaryButton from 'components/common/SecondaryButton';
import React from 'react';
import styles from 'styles/components/AccountDetails.module.sass';
import { Link} from 'react-router-dom';
import Invoices from './Invoices';
import { useAuth } from 'hooks/AuthContext';
import accountTypes from 'constants/data/AccountTypes';

const AccountDetails: React.FC = () => {
  const { userSubscription } = useAuth()

  // const paymentMethod = {
  //   brand: 'MasterCard',
  //   last4: '5678',
  //   expMonth: 11,
  //   expYear: 2025,
  //   name: 'Jane Doe',
  // }

  return (
    <div className={styles['account-details']}>
      {/* Manage Password Section */}
      {/* <div className={styles['section']}>
        <h3 className={styles['section-label']}>Manage Password</h3>
        <div className={styles['field-group']}>
          <span className={styles['field-label']}>Current password</span>
          <span className={styles['field-value']}>••••••••</span>
        </div>
        <SecondaryButton
          text="Change Password"
          primaryColor="transparent"
          textColor="#ff5522"
          hoverColor="#ff5522"
          hoverTextColor="#FFFFFF"
          doSomething={() => console.log('Change password clicked')}
        />
        
      </div> */}

      {/* Subscription Section */}
      {userSubscription && userSubscription.name && userSubscription.description && (
        <div className={styles['section']}>
          <h3 className={styles['section-label']}>Subscription</h3>
          <div className={styles['section-content']}>
            <p className={styles['section-subtitle']}>{`${userSubscription?.name.substring(0,1).toUpperCase() + userSubscription?.name.substring(1)} Plan`}</p>
            <p className={`${styles['section-text']} ${styles['subscription-description']}`}>
              {accountTypes.find(account => account.name === userSubscription.name.toLocaleLowerCase())?.description}
            </p>
            </div>
        </div>
      )}

      {/* Payment Details Section */}
      {/* <div className={styles['section']}>
        <h3 className={styles['section-label']}>Payment Details</h3>
        <div className={styles['field-group']}>
          <span className={styles['field-label']}>Current Payment Method</span>
          <div className={styles['payment-card']}>
            <span className={styles['card-brand']}>{paymentMethod.brand}</span>
            <span className={styles['card-number']}>•••• •••• •••• {paymentMethod.last4}</span>
            <span className={styles['card-expiry']}>
              Exp: {paymentMethod.expMonth}/{paymentMethod.expYear}
            </span>
          </div>
          <SecondaryButton
            text="Change Payment Method"
            primaryColor="transparent"
            textColor="#ff5522"
            hoverColor="#ff5522"
            hoverTextColor="#FFFFFF"
            doSomething={() => console.log('Change payment method clicked')}
          />
        </div>
      </div> */}

      {/* Invoices Section */}
      <div className={styles['section']}>
        <h3 className={styles['section-label']}>Invoices</h3>
        <Invoices status="succeeded" />
      </div>

      {/* Contact Support Section */}
      <div className={styles['section']}>
        <h3 className={styles['section-label']}>Contact Support</h3>
        <p className={styles['support-text']}>
          Have any concerns or challenges? {' '}
          <Link to="/contact-us" className={styles['contact-link']}>Contact our support</Link> for help.
        </p>
      </div>
    </div>
  );
};

export default AccountDetails;
