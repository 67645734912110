import { fetchApiData } from "../ApiHelper"
import { get } from "../ApiClient"
import { DefaultApiResponse } from "../models/response/DefaultApiResponse"
import EndpointProps from "interface/EndpointProps"
import Account from "interface/Account"

/**
 * Fetches account data based on the provided tag and level.
 *
 * @param {Object} params - The parameters for fetching account data.
 * @param {string} params.tag - The tag used to identify the account.
 * @param {'summary' | 'profile'} params.level - The level of account data to fetch.
 * @returns {Promise<{ success: boolean; data?: Account }>} A promise that resolves to an object containing the success status and account data.
 * @throws {Error} Throws an error if the account level data request is invalid.
 */
export async function fetchAccountData(
    { tag, level }: EndpointProps & { level: 'summary' | 'profile' }
): Promise<{ success: boolean; data?: Account }> {
    if (!level) {
        throw new Error ('Invalid account level data request.')
    }

    const result = await fetchApiData<DefaultApiResponse<Account>>(tag,
        () => get<DefaultApiResponse<Account>>({ tag, url: `/account/${level}`}),
        (responseData) => responseData.data
    )

    return {
        success: result.response.status === 'success',
        data: result.response.data
    }
}