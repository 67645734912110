import { fetchProfile } from 'api/AccountApiService';
import { fetchUserSubscription } from 'api/SubscriptionApiService';
import Account from 'interface/Account';
import UserSubscription from 'interface/UserSubscription';
import React, { createContext, useState, useEffect, useContext } from 'react'
import clearJwtToken from 'utils/ClearJwtToken'
import getJwtToken from 'utils/GetJwtToken'


/**
 * Interface describing data the context should hold
 *
 * @interface AuthContextType
 * @typedef {AuthContextType}
 */
interface AuthContextType {
    /**
     * User object null if user is not logged in.
     *
     * @type {*}
     */
    user: Account | null;
    /**
     * Subscription object null if subscription is not available.
     */
    userSubscription: UserSubscription | null;
    /**
     * Destroys current session when user logs out.
     *
     * @type {() => void}
     */
    logout: () => void
    userAuthenticated: (auth: boolean) => void;
}


/**
 * Description placeholder
 *
 * @type {*}
 */
const AuthContext = createContext<AuthContextType | null>(null)

/**
 * Description placeholder
 *
 * @export
 * @param {{ children: React.ReactNode}} param0 
 * @param {React.ReactNode} param0.children 
 * @returns {*} 
 */
export function AuthProvider({ children }: { children: React.ReactNode}) {
    const [user, setUser] = useState<Account | null >(null)
    const [userSubscription, setUserSubscription] = useState<UserSubscription | null>(null)
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    
    /**
     * Custom function to handle token changes
     */
    const updateAuthData = () => {
        const authData = getJwtToken();
        if (authData) {
            console.log('User authenticated');
            setAuthenticated(false)

            fetchProfile('summary').then((result) => {
                setUser(result as Account | null)
            }).catch((error) => {
                console.error(error)
                setUser(null)
            })

            fetchUserSubscription().then((result) => {
                setUserSubscription(result)
            }).catch((error) => {
                console.error(error)
                setUserSubscription(null)
            })
        } else {
            console.log('No user token found');
            setAuthenticated(false)
            setUser(null);
        }
    };

    const userAuthenticated = (auth: boolean) => {
        setAuthenticated(auth)
        updateAuthData()
    }

    useEffect(() => {
        // Initial authentication check
        updateAuthData();

        window.addEventListener('authChange', updateAuthData);

        return () => {
            window.removeEventListener('authChange', updateAuthData);
        };
    }, [authenticated]);

    const logout = () => {
        const controller = new AbortController()
        clearJwtToken()
        setUser(null)
        setAuthenticated(false)

        controller.abort()
        window.location.href = '/login'
    }

    return (
        <AuthContext.Provider value={{ user, userSubscription, logout, userAuthenticated }}>
            {children}
        </AuthContext.Provider>
    )
}

/**
 * Description placeholder
 *
 * @export
 * @returns {*} 
 */
export function useAuth() {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}