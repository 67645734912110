import { getBlogs, getHotTopic, getBlogPost } from "./endpoints/BlogEndpoints";
import { getReportCategories, getTopHotReports, getReport, checkAccessToReport, downloadReport, purchaseReportUsingCredits, getReportCatalog } from "./endpoints/ReportEndpoints";
import { signUpUser, logInUser } from "./endpoints/AuthEndpoints";
import { updateSubscriptionPayment, updateSubscriptionPlan, fetchSubscriptionData, getSubscriptionInvoices } from "./endpoints/SubscriptionEndpoints";
import { fetchAccountData } from "./endpoints/AccountEndpoints";

/**
 * Interface representing the endpoints related to account operations.
 */
interface AccountEndpoints {
    fetchAccountData: typeof fetchAccountData;
}

/**
 * Interface representing the endpoints related to subscription operations.
 * 
 * @interface SubscriptionEndpoints
 * 
 * @property {typeof updateSubscriptionPlan} updateSubscriptionPlan - Endpoint for updating the subscription plan.
 * @property {typeof updateSubscriptionPayment} updateSubscriptionPayment - Endpoint for updating the subscription payment details.
 * @property {typeof fetchSubscriptionData} fetchSubscriptionData - Endpoint for fetching subscription data.
 */
interface SubscriptionEndpoints {
    updateSubscriptionPlan: typeof updateSubscriptionPlan;
    updateSubscriptionPayment: typeof updateSubscriptionPayment;
    fetchSubscriptionData: typeof fetchSubscriptionData;
    getSubscriptionInvoices: typeof getSubscriptionInvoices;
}

/**
 * Interface for available auth-related endpoints.
 * 
 * @interface AuthEndpoints
 * @property {typeof signUpUser} signUpUser - Function for registering user.
 */
interface AuthEndpoints {
    signUpUser: typeof signUpUser;
    logInUser: typeof logInUser;
}

/**
 * Interface for available blog-related endpoints.
 * 
 * @interface BlogEndpoints
 * @property {typeof getBlogs} getBlogs - Function for fetching blog data.
 * @property {typeof getHotTopic} getHotTopic - Function for fetching hot topc.
 * @property {typeof getBlogPost} getBlogPost - Function for fetching blog post.
 */
interface BlogEndpoints {
    getBlogs: typeof getBlogs;
    getHotTopic: typeof getHotTopic;
    getBlogPost: typeof getBlogPost;
}

/**
 * Interface for available report-related endpoints.
 * 
 * @interface ReportEndpoints
 * @property {typeof getReportCategories} getReportCategories - Function for fetching report categories.
 */
interface ReportEndpoints {
    getReportCategories: typeof getReportCategories;
    getTopHotReports: typeof getTopHotReports;
    getReport: typeof getReport;
    checkAccessToReport: typeof checkAccessToReport;
    downloadReport: typeof downloadReport;
    purchaseReportUsingCredits : typeof purchaseReportUsingCredits;
    getReportCatalog: typeof getReportCatalog;
}

/**
 * Main interface for all available endpoints.
 * 
 * @interface Endpoints
 * @extends AuthEndpoints
 * @extends BlogEndpoints
 * @extends ReportEndpoints
 */
interface Endpoints extends AuthEndpoints, SubscriptionEndpoints, AccountEndpoints, BlogEndpoints, ReportEndpoints {}

/**
 * Centralized object containing all endpoint functions.
 * This object provides easy access to different API endpoint functions throughout the application.
 * 
 * @constant
 * @type {Endpoints}
 * 
 * @example
 * // Import the endpoints object and use it to fetch blogs
 * import endpoints from './constants/api/endpoints';
 * 
 * async function fetchBlogData() {
 *   const result = await endpoints.getBlogs({ tag: 'BlogFetch' });
 *   if (result.error) {
 *     console.error('Error fetching blogs:', result.error);
 *   } else {
 *     console.log('Fetched blogs:', result.data);
 *   }
 * }
 * 
 * fetchBlogData();
 */
const endpoints: Endpoints = {
    signUpUser,
    logInUser,
    updateSubscriptionPlan,
    updateSubscriptionPayment,
    fetchSubscriptionData,
    fetchAccountData,
    getBlogs,
    getHotTopic,
    getBlogPost,
    getReportCategories,
    getTopHotReports,
    getReport,
    checkAccessToReport,
    downloadReport,
    purchaseReportUsingCredits,
    getReportCatalog,
    getSubscriptionInvoices
};

export default endpoints;