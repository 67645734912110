import PrimaryButton from 'components/common/PrimaryButton';
import React, { useEffect, useState } from 'react'
import styles from 'styles/components/ProfilePlanSummaryCard.module.sass';


/**
 * Props for the ProfilePlanSummaryCard component.
 */
interface ProfilePlanSummaryCardProps {
    /**
     * The name of the plan.
     */
    planName: string;

    /**
     * A description of the plan.
     */
    planDescription: string;

    /**
     * Callback function to handle subscription changes.
     */
    onChangeSubscription: () => void;
}


/**
 * ProfilePlanSummaryCard component displays the current subscription plan details
 * and provides an option to change the subscription.
 *
 * @component
 * @param {ProfilePlanSummaryCardProps} props - The properties object.
 * @param {string} props.planName - The name of the current plan.
 * @param {string} props.planDescription - A brief description of the current plan.
 * @param {() => void} props.onChangeSubscription - Callback function to handle subscription change.
 *
 * @example
 * <ProfilePlanSummaryCard
 *   planName="Premium Plan"
 *   planDescription="Access to all premium features"
 *   onChangeSubscription={() => console.log('Change subscription clicked')}
 * />
 */
const ProfilePlanSummaryCard: React.FC<ProfilePlanSummaryCardProps> = ({ planName, planDescription, onChangeSubscription }) => {
    const [planColor, setPlanColor] = useState<string>('#216f97')

    useEffect(() => {
        switch (planName.toLowerCase()) {
            case 'basic':
                setPlanColor('#216f97')
                break
            case 'sapphire':
                setPlanColor('#003FD1')
                break
            case 'gold':
                setPlanColor('#E88B00')
                break
            case 'platinum':
                setPlanColor('#BCBCBC')
                break
            default :
                setPlanColor('#707070')
                break
        }
    }, [planName])

    return (
        <div className={styles['profile-plan-summary-card']}>
            <p className={styles['plan-summary-title']}>Current Plan</p>
            <h2 className={styles['plan-name']} style={{ color: planColor }}>{planName}</h2>
            <p className={styles['plan-description']}>{planDescription}</p>
            <PrimaryButton
                text='Change Subscription'
                primaryColor='#FF5522'
                textColor='#FFFFFF'
                hoverColor='#FFFFFF'
                hoverTextColor='#FF5522'
                icon={''}
                doSomething={onChangeSubscription}
                width='100%'
            />
        </div>
    )
}

export default ProfilePlanSummaryCard;