import { fetchSubscriptionInvoices } from 'api/SubscriptionApiService';
import ReusableIcon from 'components/common/ReusableIcon';
import SubscriptionInvoice from 'interface/SubscriptionInvoice'
import React, { useEffect } from 'react'
import styles from 'styles/components/Invoices.module.sass'

/**
 * Props for the Invoices component.
 */
interface InvoicesProps {
    status: 'succeeded' | 'processing' | 'canceled' | 'failed';
}

/**
 * Invoices component - Displays a list of invoices.
 *
 * This component displays a list of invoices that belong to the user.
 *
 * @component
 * @param {InvoicesProps} props - The properties for the Invoices component.
 * @param {'succeeded' | 'processing' | 'canceled' | 'failed'} props.status - The status of the invoices to be displayed.
 *
 * @example
 * // Example usage:
 * <Invoices status="succeeded" />
 *
 * @returns {JSX.Element} The rendered Invoices component.
 */
const Invoices: React.FC<InvoicesProps> = ({ status }) => {
    const [invoices, setInvoices] = React.useState<SubscriptionInvoice[]>([]);

    useEffect(() => {
        const fetchInvoices = async () => {
            try {
                // Fetch invoices
                const fetchedInvoices = await fetchSubscriptionInvoices({ tag: 'Invoices', status: status })
                setInvoices(fetchedInvoices)
            } catch {
                setInvoices([])
            }
        }

        fetchInvoices()
    }, [status]);

    return (
        <div className={styles['invoices']}>
            <table className={styles['invoices-table']}>
                <thead className={styles['invoices-table-header']}>
                    <tr className={styles['invoices-table-row']}>
                        <th className={styles['invoices-table-header-cell']}>Invoice ID</th>
                        <th className={styles['invoices-table-header-cell']}>Description</th>
                        <th className={styles['invoices-table-header-cell']}>Amount</th>
                        <th className={styles['invoices-table-header-cell']}>Date</th>
                        <th className={styles['invoices-table-header-cell']}>Action</th>
                    </tr>
                </thead>
                <tbody className={styles['invoices-table-body']}>
                    {invoices.map((invoice, index) => (
                        <tr key={index} className={styles['invoices-table-row']}>
                            <td className={styles['invoices-table-body-cell']}>{invoice.invoiceId}</td>
                            <td className={styles['invoices-table-body-cell']}>{invoice.description}</td>
                            <td className={styles['invoices-table-body-cell']}>{invoice.amount}</td>
                            <td className={styles['invoices-table-body-cell']}>{invoice.createdAt ? new Date(invoice.createdAt).toLocaleString('en-US') : ''}</td>
                            <td className={styles['invoices-table-body-cell']}>
                                <a href={invoice.receiptUrl ? invoice.receiptUrl : '#'} target="_blank" rel="noreferrer" className={styles['download-link'] || 'download-link'}>
                                    <ReusableIcon icon={'material-symbols-light:download-rounded'} className={styles['download-icon'] || 'download-icon'} color={'#1e1e1e'} />
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Invoices;