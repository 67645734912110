import React, { useEffect, useState } from 'react'
import styles from 'styles/pages/ProfilePage.module.sass'
import { fetchProfile } from 'api/AccountApiService'
import BasicProfile from 'interface/BasicProfile'
import logInfo from 'services/logService/infoLogger'
import ZeroneLoader from 'components/common/ZeroneLoader'
import ProfileHeaderSection from 'layouts/section/ProfileHeaderSection'
import ProfileBodySection from 'layouts/section/ProfileBodySection'
import FooterMain from 'components/layout/footer/FooterMain'

const TAG = 'ProfilePage'

/**
 * ProfilePage component - A page layout for displaying user profile details.
 *
 * This component fetches the user profile data and displays it using the
 * `ProfileHeaderSection` and `ProfileBodySection` components. It also includes
 * a footer section (`FooterMain`) for consistent layout.
 *
 * @component
 * @example
 * // Example usage:
 * <ProfilePage />
 *
 * @returns {JSX.Element} The rendered ProfilePage component.
 */
const ProfilePage: React.FC = () => {
    const [profile, setProfile] = useState<BasicProfile | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [tab, setTab] = useState<'basic' | 'reports' | 'account'>('basic')

    useEffect(() => {
        const loadProfile = async () => {
            setLoading(true)
            try {
                const fetchedProfile = await fetchProfile('profile')
                setProfile(fetchedProfile as BasicProfile | null)

                if (fetchedProfile) {
                    logInfo(TAG, { message: 'Profile loaded', fetchProfile }, TAG)
                }
            } catch {
                setProfile(null)
            } finally {
                setLoading(false)
            }
        }

        loadProfile()
    }, [])

    return (
        <div className={styles['profile-page']}>
            {loading && (
                <div className={styles['loader']}>
                    <ZeroneLoader text='Loading profile...' />
                </div>
            )}
            <ProfileHeaderSection 
                profile={profile}
                tab={tab}
                setTab={setTab}
            />
            <ProfileBodySection profile={profile} tab={tab} />
            <FooterMain />
        </div>
    )
}

export default ProfilePage;
