import React from 'react'
import styles from 'styles/layout/ReportHeaderSection.module.sass'
import HeaderNavigation from '../header/HeaderNavigation';
import ReportSummary from 'components/common/ReportSummary';
import ZeroneReport from 'interface/ZeroneReport';

interface ReportHeaderSectionProps {
    report: ZeroneReport | null;
    ableToPurchase: boolean;
    hasAccessToReport: boolean;
    handleBuyReportClick: () => void;
}

/**
 * ReportHeaderSection component - Displays the header section for a report.
 *
 * This component includes a navigation header (`HeaderNavigation`) and a summary
 * of the report (`ReportSummary`). The header is styled for a dark theme, and
 * the summary is rendered only if a valid report is passed as a prop.
 *
 * @component
 * @param {ReportHeaderSectionProps} props - The properties for the ReportHeaderSection component.
 * @param {ReportContent | null} props.report - The report data to be displayed in the header section.
 *
 * @example
 * // Example usage:
 * <ReportHeaderSection report={reportData} />
 *
 * @returns {JSX.Element} The rendered ReportHeaderSection component.
 */
const ReportHeaderSection: React.FC<ReportHeaderSectionProps> = ({ report, ableToPurchase, hasAccessToReport, handleBuyReportClick }) => {
    

    const handleBuyClicked = () => {
        console.log('Buy clicked: Header');
        handleBuyReportClick();
    }

    return (
        <section className={styles['report-header-section']}>
            <HeaderNavigation theme='dark' />

            {report && (
                <ReportSummary report={report} ableToPurchase={ableToPurchase} hasAccessToReport={hasAccessToReport} handleBuyReportClick={handleBuyClicked} />
            )}
        </section>
    )
}

export default ReportHeaderSection;