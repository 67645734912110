import { PriceCardProps } from 'interface/PriceCardProps';

/**
 * sapphirePricePlan object defines the properties and features of the sapphire subscription plan.
 * It includes pricing details, a selection of features, and a click handler for the subscription button.
 *
 * @constant
 *
 * @type {PriceCardProps}
 *
 * @example
 * console.log(sapphirePricePlan.moreInfo);
 * // Output: "5 User  Seats"
 *
 * @see PriceCardProps - Interface representing the structure of a price plan.
 */
export const sapphirePricePlan: PriceCardProps = {
  subscriptionType: 'sapphire',
  moreInfo: '5 User Seats',
  price: '199.5',
  nonAfricanPrice: '399',
  subscriptionTerm: 'per month',
  features: [
    'Access to AI-Powered Workspace',
    '5 Workspace Projects per user per month',
    'Access to 3 report credits per month',
    'On-demand training',
  ],
  onButtonClick() {
    console.log('Sapphire plan clicked');
  },
};
