/**
 * Retrieves the JWT token from cookies or sessionStorage.
 *
 * @returns {string | null} The JWT token if found, otherwise null.
 */
const getJwtToken = (): string | null => {
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('auth_token='))
        ?.split('=')[1];
    if (cookieValue) {
        return cookieValue;
    }

    return sessionStorage.getItem('auth_token');
}

export default getJwtToken;