import React from "react";
import styles from 'styles/components/UnlimitedReportAccessCard.module.sass';
import bagIcon from 'assets/images/svg/bag-dynamic.svg';
import PrimaryButton from "components/common/PrimaryButton";
import SecondaryButton from "components/common/SecondaryButton";
import { useNavigate } from "react-router-dom";
import ReusableIcon from "components/common/ReusableIcon";

interface UnlimitedReportAccessCardProps {
    onBuyOnlyThisReport: () => void;
    onViewAllPlans: () => void;
    onClose: () => void;
}

/**
 * UnlimitedReportAccessCard component displays a card with a promotional message
 * encouraging users to buy a report or view all available plans.
 *
 * This card is displayed when a user tries to access a report without a subscription.
 *
 * @component
 * @param {UnlimitedReportAccessCardProps} props - The properties for the UnlimitedReportAccessCard component.
 * @param {() => void} props.onBuyOnlyThisReport - The callback function to buy only the current report.
 * @param {() => void} props.onViewAllPlans - The callback function to view all available plans.
 * @param {() => void} props.onClose - The callback function to close the card.
 *
 * @example
 * // Example usage:
 * <UnlimitedReportAccessCard
 *   onBuyOnlyThisReport={() => console.log('Buy button clicked')}
 *   onViewAllPlans={() => console.log('View all plans clicked')}
 *   onClose={() => console.log('Close button clicked')}
 * />
 *
 * @returns {JSX.Element} The rendered UnlimitedReportAccessCard component.
 */
const UnlimitedReportAccessCard: React.FC<UnlimitedReportAccessCardProps> = ({
    onBuyOnlyThisReport,
    onViewAllPlans,
    onClose}) => {

    const navigate = useNavigate()

    const handleLoginClicked = () => {
        navigate('/login');
    };

    return (
        <div className={styles['unlimited-report-access-card']}>
            <button className={styles['close-button']} onClick={onClose}>
                <ReusableIcon 
                    icon={"qlementine-icons:close-16"} 
                    className={styles['close-icon'] || 'close-icon'}
                    color={"#707070"}
                />
            </button>
            <div className={styles['main-content']}>
                <div className={styles['left-wrapper']}>
                    <img src={bagIcon} alt="Unlimited report access icon" className={styles['bag-icon']} />
                </div>
                <div className={styles['right-wrapper']}>
                    <div className={styles['text-wrapper']}>
                        <p className={styles['main-title']}>Unlimited Access</p>
                        <p className={styles['description-text']}>
                            Get all these reports at your fingertips with one of our <strong>Business</strong> or <strong>Individual</strong> Plans.
                        </p>
                    </div>
                    <div className={styles['button-wrapper']}>
                        <PrimaryButton 
                            text="View Plans"
                            primaryColor="#ff5522"
                            textColor="#fff"
                            hoverColor="#fff"
                            hoverTextColor="#ff5522"
                            width="100%"
                            doSomething={() => onViewAllPlans}
                        />
                        <SecondaryButton
                            text="Buy only this report"
                            primaryColor="transparent"
                            textColor="#fff"
                            hoverColor="#ff5522"
                            width="100%"
                            hoverTextColor="#ff5522"
                            doSomething={() => onBuyOnlyThisReport}
                        />
                    </div>
                    <div className={styles['divider']} />
                    <p className={styles['note-text']}>
                        If you already have a plan, <a className={styles['login']} href="/login" onClick={handleLoginClicked}>Login</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default UnlimitedReportAccessCard;