import React, { useEffect, useState } from 'react'
import styles from 'styles/components/MyReports.module.sass'
import ReportCatalog from 'interface/ReportCatalog';
import { fetchReportCatalog } from 'api/ReportApiService';
import CatalogCard from 'components/cards/CatalogCard';

const TAG = 'MyReports'

/**
 * MyReports component - Displays a list of reports.
 *
 * This component displays a list of reports that belong to the user.
 *
 * @component
 * @param {MyReportsProps} props - The properties for the MyReports component.
 * @param {ReportCatalog[]} props.reports - The reports to be displayed.
 *
 * @example
 * // Example usage:
 * <MyReports reports={reportsData} />
 *
 * @returns {JSX.Element} The rendered MyReports component.
 */
const MyReports: React.FC = () => {
    const [catalog, setCatalog] = useState<ReportCatalog[]>([])

    useEffect(() => {
        const loadCatalog = async () => {
            try {
                const fetchedCatalog = await fetchReportCatalog(TAG)
                setCatalog(fetchedCatalog)
                console.log(fetchedCatalog)
            } catch {
                setCatalog([])
            }
        }

        loadCatalog()
    }, [])

    return (
        <div className={styles['my-reports']}>
            <div className={styles['reports-list']}>
                {catalog.map((report, index) => (
                    <CatalogCard key={index} catalog={report} />
                ))}
            </div>
        </div>
    );
}

export default MyReports;