import SecondaryButton from 'components/common/SecondaryButton';
import BasicProfile from 'interface/BasicProfile';
import React from 'react'
import styles from 'styles/components/BasicInfo.module.sass'

interface BasicInfoProps {
    profile: BasicProfile;
}

/**
 * BasicInfo component - Displays the basic information of a profile.
 *
 * This component includes the profile name, email, and phone number.
 *
 * @component
 * @param {BasicInfoProps} props - The properties for the BasicInfo component.
 * @param {BasicProfile} props.profile - The profile data to be displayed.
 *
 * @example
 * // Example usage:
 * <BasicInfo profile={profileData} />
 *
 * @returns {JSX.Element} The rendered BasicInfo component.
 */
const BasicInfo: React.FC<BasicInfoProps> = ({ profile }) => {
    return (
        <div className={styles['basic-info']}>
            <div className={`${styles['basic-info-item']} ${styles['left']}`}>
                <SecondaryButton
                    width="auto"
                    text="Edit profile"
                    primaryColor="#FFFFFF"
                    textColor="#ff5522"
                    hoverColor="#ff5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={() => console.log('Edit profile')}
                />
            </div>
            <div className={styles['basic-info-item']}>
                <p className={styles['label']}>First name</p>
                <p className={styles['value']}>{profile.firstName}</p>
            </div>
            <div className={styles['basic-info-item']}>
                <p className={styles['label']}>Last name</p>
                <p className={styles['value']}>{profile.lastName}</p>
            </div>
            <div className={styles['basic-info-item']}>
                <p className={styles['label']}>Gender</p>
                <p className={styles['value']}>{profile.gender}</p>
            </div>
            <div className={styles['basic-info-item']}>
                <p className={styles['label']}>Email</p>
                <p className={styles['value']}>{profile.email}</p>
            </div>
            <div className={styles['basic-info-item']}>
                <p className={styles['label']}>Phone number</p>
                <p className={styles['value']}>{profile.phoneNumber}</p>
            </div>
            <div className={styles['basic-info-item']}>
                <p className={styles['label']}>Country</p>
                <p className={styles['value']}>{profile.country}</p>
            </div>
            <div className={styles['basic-info-item']}>
                <p className={styles['label']}>Company name</p>
                <p className={styles['value']}>{profile.companyName}</p>
            </div>
            <div className={styles['basic-info-item']}>
                <p className={styles['label']}>Interested Industries</p>
                <p className={styles['value']}>{''}</p>
            </div>
        </div>
    )
}

export default BasicInfo;