import React from 'react';
import styles from 'styles/components/CatalogCard.module.sass'
import ReportCatalog from 'interface/ReportCatalog';
import { useNavigate } from 'react-router-dom'

interface CatalogCardProps {
    catalog: ReportCatalog;
}

/**
 * CatalogCard component - Displays a card for a report in the catalog.
 *
 * This component displays a card for a report in the catalog. The card includes
 * the report title, description, and a link to view the report.
 *
 * @component
 * @param {CatalogCardProps} props - The properties for the CatalogCard component.
 * @param {ReportCatalog} props.catalog - The report to be displayed in the card.
 *
 * @example
 * // Example usage:
 * <CatalogCard report={reportData} />
 *
 * @returns {JSX.Element} The rendered CatalogCard component.
 */
const CatalogCard: React.FC<CatalogCardProps> = ({ catalog }) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/report/${catalog.report_id}`)
    }

    return (
        <div className={styles['catalog-card']} key={catalog.report_id} onClick={handleClick}>
            <div className={styles['catalog-image']} style={{ backgroundImage: `url(${catalog.meta.cover || ''})` }} />
            <div className={styles['catalog-info']}>
                <p className={styles['catalog-dataset']}>{catalog.dataset}</p>
                <h3 className={styles['catalog-title']}>{catalog.title}</h3>
                <p className={styles['catalog-description']}>{catalog.description}</p>
                <div className={styles['author']}>
                    <img src={catalog.author.imageUrl} alt={catalog.author.name} className={styles['author-image']} />
                    <p className={styles['name-role']}>by <span className={styles['author-name']}>{catalog.author.name}</span> • <span className={styles['published']}>{catalog.meta.published}</span></p>
                </div>
            </div>
        </div>
    );
}

export default CatalogCard;