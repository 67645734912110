/**
 * Clears the JWT token from the session storage.
 *
 * This function removes the 'auth_token' item from the session storage,
 * effectively logging the user out or invalidating the current session.
 *
 * @returns {void}
 */
const clearJwtToken = (): void => {
    sessionStorage.removeItem('auth_token')
}

export default clearJwtToken;