import React, { useEffect, useState } from 'react'
import styles from 'styles/components/ProfileHeaderSummary.module.sass'
import BasicProfile from 'interface/BasicProfile'
import ProfilePlanSummaryCard from 'components/cards/ProfilePlanSummaryCard';
import { useAuth } from 'hooks/AuthContext';
import ReusableIcon from 'components/common/ReusableIcon';
import botImage from 'assets/images/svg/zerone-bot-3-d-2.svg'

/**
 * Props for the ProfileHeaderSummary component.
 */
interface ProfileHeaderSummaryProps {
    profile: BasicProfile;
    tab: 'basic' | 'reports' | 'account';
    setTab: (tab: 'basic' | 'reports' | 'account') => void;
}

/**
 * The tab options for the profile summary.
 */
const tabOptions = {
    basic: 'Basic Info',
    reports: 'My Reports',
    account: 'Account Details'
}

/**
 * ProfileHeaderSummary component displays the profile summary details
 * and provides navigation options to view different sections of the profile.
 *
 * @component
 * @param {ProfileHeaderSummaryProps} props - The properties object.
 * @param {BasicProfile} props.profile - The profile data to be displayed.
 * @param {'basic' | 'reports' | 'account'} props.tab - The active tab in the profile summary.
 * @param {(tab: 'basic' | 'reports' | 'account') => void} props.setTab - Callback function to set the active tab.
 *
 * @example
 * <ProfileHeaderSummary
 *   profile={profileData}
 *   tab='basic'
 *   setTab={(tab) => console.log(tab)}
 * />
 */
const ProfileHeaderSummary: React.FC<ProfileHeaderSummaryProps> = ({ profile, tab, setTab }) => {
    const [name, setName] = useState<string>('')
    const { userSubscription } = useAuth()

    useEffect(() => {
        if (profile) {
            setName(`${profile.firstName} ${profile.lastName}`)
        }
    }, [profile])
    console.log('ProfileHeaderSummary', profile, tab, setTab)
    return (
        <div className={styles['profile-header-summary']}>
            <div className={styles['main-wrapper']}>
                <div className={styles['left-section']}>
                    <div className={styles['profile-picture-wrapper']}>
                        <img src={profile.profilePicture.length > 0 ? profile.profilePicture : botImage} alt={`${name}'s profile`} className={styles['profile-picture']} />
                        <div className={styles['camera-overlay']}>
                            <ReusableIcon icon='mdi:camera' className={styles['camera-icon'] || 'camera-icon'} color='#fff' />
                            <p className={styles['camera-text']}>Change photo</p>
                        </div>
                    </div>
                    <div className={styles['profile-details']}>
                        <p className={`${styles['profile-item']} ${styles['name']}`}>{name}</p>
                        <p className={`${styles['profile-item']}`}>
                            <ReusableIcon icon='mdi:location' className={styles['icon'] || 'icon'} color={''}/>
                            {profile.country}
                        </p>
                        <p className={`${styles['profile-item']} ${styles['credit-balance']}`}>{profile.creditBalance} Credits</p>
                    </div>
                </div>
                <div className={styles['right-section']}>
                    <ProfilePlanSummaryCard
                        planName={userSubscription?.name || ''}
                        planDescription={userSubscription?.description || ''}
                        onChangeSubscription={() => console.log('Change subscription clicked')}
                    />
                </div>
            </div>
            
            <div className={styles['tab-section']}>
                <div className={styles['tabs']}>
                    {Object.keys(tabOptions).map((key, index) => (
                        <button
                            key={index}
                            className={`${styles['tab-button']} ${tab === key ? styles['active'] : ''}`}
                            onClick={() => setTab(key as 'basic' | 'reports' | 'account')}
                        >
                            {tabOptions[key as keyof typeof tabOptions]}
                            {tab === key && <div className={styles['active-indicator']}></div>}
                        </button>
                    ))}
                </div>
                <div className={styles['tab-bar']}></div>
            </div>
        </div>
    )
}

export default ProfileHeaderSummary;